import React, { useEffect } from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import $ from "jquery";
import locales from "../../constants";

const Rspwn = ({ pageContext: { slug } }) => {
  useEffect(() => {
    $(".glitchHoverEffect").hover(function () {
      $(".glitch").toggleClass("effectOn");
    });
  });
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #c82137, #ea253f, #ff3a5f, #ea253f, #c82137 )",
        icons: "#C82137",
        navClass: "rspwn",
      }}
      seo={{
        title: "RSPWN",
        headerTitle: "rspwn",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/rspwn_top_back.png"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/rspwn/",
      }}
    >
      <PortfolioHeader name="rspwn" height="75" />
      <section className="container-fluid rspwn_section_2" id="info">
        <div className="row">
          <div className="col-lg-6">
            <div className="inner">
              <h1>RSPWN</h1>
              <ul>
                <li>Logo</li>
                <li>Sklep internetowy</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="inner">
              <p>
                RSPWN to nowa gamewearowa marka dedykowana wszystkim hardkorowym
                graczom. Nie ważne czy Twoim hymnem jest “EA Sport Cyny gejm”
                czy też wolisz przemierzać Summoner's Rift rozstawiając wszędzie
                niewidzialne, trujące grzyby czy grać AP LUX na supporcie. Ba,
                nawet jeśli jesteś strategiem spod znaku “Rush B!” to każdy Twój
                respawn na rspwn.gg jest mile widziany!
              </p>
              <p>
                Dla marki, której twarzą został jeden z najbardziej
                rozpoznawalnych zespołów esportowych w Polsce - IZAKO BOARS,
                wykonaliśmy logo oraz model funkcjonalny sklepu internetowego
                (UX/UI) na bazie których zaprojektowaliśmy i wdrożyliśmy
                platformę sprzedażową.
              </p>
              <h3>Logo</h3>
              <p>
                <strong>
                  R<span>E</span>SP<span>A</span>WN
                </strong>{" "}
                to odrodzenie - gdy w grze stracisz życie masz szansę zacząć od
                nowa odradzając się na starcie. W zaprojektowanym przez nas
                znaku symbolizują to, zwrócone w przeciwnych kierunkach strzałki
                ukazane w przestrzeni negatywnej logotypu.
              </p>
              <p>
                Prosta, pogrubiona typografa bezszeryfowa, podkreśla
                profesjonalizm i solidność marki, a niewielkie pochylenie liter,
                jej dynamizm. W końcu świat gamingu i jego fani to wymagająca
                branża, w której ciągły rozwój to podstawa.
              </p>
              <p>
                Tylko tyle i aż tyle, nie ma tu miejsca na udziwnienia. RSPWN to
                konkretna marka dla tych, którzy zawsze walczą do końca.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid rspwn_section_3">
        <div className="row">
          <div className="col-lg-6 col_1">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/rspwn_logo_colors.png")}
              alt=""
            />
            <div className="img_wrapper">
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/rspwn_logo.svg")}
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-4 offset-lg-2 col_2">
            <div className="row">
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Black CMYK</p>
                    <h5>
                      <span>0</span>
                      <span>0</span>
                      <span>0</span>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 100 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Gold CMYK</p>
                    <h5>
                      <CountUp start={0} duration={3} end={isVisible ? 0 : 0} />
                      <CountUp start={0} duration={3} end={isVisible ? 0 : 0} />
                      <CountUp start={0} duration={3} end={isVisible ? 0 : 0} />
                      <CountUp start={0} duration={3} end={isVisible ? 0 : 0} />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>0</span>
                  <span>0</span>
                  <span>0</span>
                </h5>
              </div>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>RGB</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 255 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 255 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 255 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>000000</h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>ffffff</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="rspwn_section_4">
        <div className="row no-gutters">
          <div className="col-lg-5">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/rspwn_main_1.png")}
              alt=""
            />
          </div>
          <div className="col-lg-6 offset-lg-1 col_2">
            <div className="inner">
              <h3>Makieta</h3>
              <p>
                Wszędzie tam gdzie celem jest konwersja odwiedzającego w
                sprzedaż, szczególną wagę przywiązujemy do doświadczeń
                użytkownika mających wpływ na podejmowanie przez niego decyzji
                zakupowe. Te często są emocjonalne, dlatego aby maksymalizować
                szansę na konwersję, prototyp został zaprojektowany w taki
                sposób, aby serwis skutecznie realizował cele omówione z
                Klientem podczas warsztatów projektowych.
              </p>
              <p>
                Makieta powstała w oparciu o potrzeby przyszłych użytkowników
                oraz najlepsze rozwiązania, stosowane obecnie w e-commerce.
                Dzięki licznym nawiązaniom do zespołu, budujemy poczucie
                przynależności i wspólnoty.
              </p>
            </div>
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/rspwn_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="rspwn_section_5">
        <div className="row no-gutters">
          <div className="col_1">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/rspwn_main_3.png")}
              alt=""
            />
          </div>
          <div className="col-lg-5 offset-lg-4 col-md-7 col_2">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/rspwn_main_4.png")}
              alt=""
            />
          </div>
          <div className="col-lg-3 col-md-5 col_3">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/rspwn_main_5.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="rspwn_section_6">
        <div className="row no-gutters">
          <div className="col-xl-3 col-md-4">
            <div className="glitch glitch--style-1">
              <div className="glitch__img"></div>
              <div className="glitch__img"></div>
              <div className="glitch__img"></div>
              <div className="glitch__img"></div>
              <div className="glitch__img"></div>
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/rspwn_main_6.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-6 offset-xl-2 col-md-6 offset-md-1">
            <div className="inner">
              <h3>Sklep internetowy</h3>
              <p>
                <strong className="glitchHoverEffect">Kolekcja CORE</strong>{" "}
                sygnowana znakiem RSPWN oraz{" "}
                <strong className="glitchHoverEffect">
                  kolekcja IZAKO BOARS
                </strong>{" "}
                zostały świetnie zaprojektowane, dlatego musieliśmy przygotować
                dla nich odpowiednie tło. Prezentacja produktów jest niezwykle
                ważna, zrezygnowaliśmy, więc z niepotrzebnych ozdobników i
                rozpraszaczy stawiając na prosty design oscylujący wokół
                tematyki (np. pixelowa ikonka ‘dodaj do ulubionych’). Ponownie
                postawiliśmy na prostotę i dynamizm, w końcu strona musi być
                dopasowana do swoich odbiorców. Tutaj miały królować i królują
                produkty.
              </p>
            </div>
          </div>
        </div>
        <div className="img_wrapper">
          <video
            width="100%"
            height="260px"
            loop
            autoPlay
            muted
            data-setup="{}"
          >
            <source
              src={require("../../assets/video_portfolio/rspwn_main_7.webm")}
              type="video/webm"
            />
            <source
              src={require("../../assets/video_portfolio/rspwn_main_7.mp4")}
              type="video/mp4"
            />
          </video>
        </div>
      </section>

      <section className="rspwn_section_7">
        <div className="row no-gutters">
          <div className="col-lg-5 offset-lg-1 col-md-6">
            <div className="inner">
              <p>
                W wyniku analizy potrzeb, planów rozwoju marki w zakresie oferty
                produktowej oraz budżetu, wspólnie zdecydowaliśmy, że na tym
                etapie najlepszym rozwiązaniem będzie oparcie wdrożenia o
                elastyczny silnik WooCommerce, który realizuje wszystkie
                założone potrzeby projektu. Zadbaliśmy również o komfortowe
                korzystanie ze sklepu na urządzeniach mobilnych, aby sklep był
                dostępny dla wszystkich swoich klientów.
              </p>
              <h3>GL&HF!</h3>
            </div>
          </div>
          <div className="col-lg-4 offset-lg-2 col-md-6 text-right">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/rspwn_main_8.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="rspwn_section_8">
        <div className="row no-gutters">
          <div className="col-md-5">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/rspwn_main_9.png")}
              alt=""
            />
          </div>
          <div className="col-md-3">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/rspwn_main_10.png")}
              alt=""
            />
          </div>
          <div className="col-md-3 offset-md-1 col_3">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/rspwn_main_11.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default Rspwn;
